import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Injectable,
  PLATFORM_ID,
  RendererFactory2,
  inject,
  isDevMode,
} from '@angular/core';
import { SessionStorageService } from '@app/core';
import { SessionDto } from '@app/core/dto/session.dto';
import { TrackingDto } from '@app/core/dto/tracking-data.dto';
import { UserContext } from '@app/core/dto/user-context-cart.dto';
import { environment } from '@env/environment';

declare var dataLayer: any;

@Injectable({
  providedIn: 'root',
})
export class GtmService {
  private readonly GTM_LOAD_KEY = 'gtm-loaded';

  userContext: UserContext;
  userSession: SessionDto;
  private pageType: string;

  private _defaultDelay = 3000;
  private _minimalDelay = 3000;
  private _rendererFactory = inject(RendererFactory2);
  private _document: Document = inject(DOCUMENT);
  private platformId: Object = inject(PLATFORM_ID);
  private readonly _sessionStorageService = inject(SessionStorageService);

  async inject() {
    if (isPlatformBrowser(this.platformId)) {
      const gtmId = environment.gtmAnalyticsKey;
      const isGtmLoaded = this._sessionStorageService.get(this.GTM_LOAD_KEY);
      if (!gtmId) {
        console.error('GTM ID is required');
        return;
      }
      await this._delay(isGtmLoaded ? this._minimalDelay : this._defaultDelay);
      this._injectGtmScript(gtmId, isGtmLoaded);
      this._injectGtmIframe(gtmId);
      this._sessionStorageService.set(this.GTM_LOAD_KEY, true);
    } else {
      console.error('GTM Should not inject on server');
    }
  }

  private _injectGtmScript(gtmId: string, isGtmLoaded: boolean = false) {
    const loadAsync = true;
    const scriptTagId = 'gtm-script';
    const existingScriptTag = this._document.getElementById(scriptTagId);
    if (!existingScriptTag) {
      const renderer = this._rendererFactory.createRenderer(null, null);
      let script = renderer.createElement('script');
      script.type = 'text/javascript';
      script.id = scriptTagId;
      script.async = true;
      script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=${loadAsync};j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
      renderer.appendChild(this._document.head, script);
    }
  }

  private _injectGtmIframe(gtmId: string) {
    const scriptTagId = 'gtm-iframe-script';
    const existingScriptTag = this._document.getElementById(scriptTagId);
    if (!existingScriptTag) {
      const renderer = this._rendererFactory.createRenderer(null, null);
      const noscript = renderer.createElement('noscript');
      noscript.id = scriptTagId;
      const iframe = renderer.createElement('iframe');
      renderer.setAttribute(
        iframe,
        'src',
        `https://www.googletagmanager.com/ns.html?id=${gtmId}`,
      );
      renderer.setAttribute(iframe, 'height', '0');
      renderer.setAttribute(iframe, 'width', '0');
      renderer.setAttribute(iframe, 'style', 'display:none;visibility:hidden');
      renderer.appendChild(noscript, iframe);
      renderer.appendChild(this._document.body, noscript);
    }
  }

  setUserContext(userContext: UserContext) {
    this.userContext = userContext;
  }

  setUserSession(userSession: SessionDto) {
    this.userSession = userSession;
  }

  setPageType(pageType: string) {
    this.pageType = pageType;
  }

  // public sendPageView(pageUrl: string) {
  //   const d = { page_location: pageUrl };
  //   this.sendEvent('page_view', d);
  // }

  public sendEvent(eventName: string, data: any): boolean {
    if (isPlatformBrowser(this.platformId)) {
      if(isDevMode()){
        console.log('incoming', eventName);
      }
      const userData = {
        user_type: data?.user_type || this.userSession?.userType,
        user_b2b: this.userSession?.isBusinessUser,
        user_gstin_verified: this.userSession?.isGstinVerified,
        user_id: this.userSession?.userId,
        user_first_name: data?.user_first_name || this.userSession?.firstName,
        page_type: data?.page_type || this.pageType,
      };
      const eventData: TrackingDto = { ...data, event: eventName, ...userData };
      if (this.isAvailable()) {
        dataLayer.push(eventData);
        return true;
      } else {
        return false;
      }
    }
  }

  public isAvailable() {
    if (typeof dataLayer != 'undefined' && dataLayer) {
      return true;
    }
    return false;
  }

  private _delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
