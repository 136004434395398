import {
  AbstractControl,
  FormGroup,
  ValidatorFn,
  ValidationErrors,
} from '@angular/forms';

export class GlobalValidator {
  static readonly EMAIL_REGEXP =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  static readonly PINCODE_REGEX = /^\d{6}$/;
  static readonly OTP_REGEX = /^\d{6}$/;
  static readonly PHONE_REGEX = /^[6-9]\d{9}$/;
  static readonly NAME_REGEX = /^[A-Za-z .-]{1,40}$/;
  static readonly TIN_REGEX = /^[0123]{1}[0-9]{1}\d{9}$/;
  static readonly AADHAR_REGEX = /^[0-9]{4}[ ]?[0-9]{4}[ ]?[0-9]{4}$/;
  static readonly PAN_REGEX = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  static readonly GSTIN_OR_TIN_REGEX = /(^[0-3][0-9]\d{9}$|^[0-3][0-9]\w{13}$)/;
  static readonly GSTIN_REGEX = /^([0][1-9]|[1-2][0-9]|[3][0-7])\w{13}$/;
    // /^[0-3]{1}[0-9]{1}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/;
  static readonly ACCOUNTNUMBER_REGEX = /^(?=[A-Za-z]*[0-9])\w{8,18}$/;
  static readonly IFSC_REGEX = /^[A-Z]{4}\d{7}$/;
  static readonly ONLY_SPACE = /.*\S.*/;
  static readonly NUMBERS_ONLY = /^[0-9]*$/;
  static readonly COMPANY_NAME_REGEX = /^[a-zA-Z0-9\s\.\&\-']{1,100}$/;
  static readonly ALPHANUMERIC_WITH_SPACE_AND_HYPHEN_PATTERN =
    /^[A-Za-z0-9\s\-]{1,40}$/;
  static readonly POSITIVE_NUM_REGEX = /^0*?[1-9]\d*$/;

  static isNonEmptyString(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value || typeof value !== 'string' || value.trim().length === 0) {
        return { isEmptyString: true };
      }

      return null;
    };
  }

  static validateForm(
    form: FormGroup,
    validationMessages: ValidationMessages,
    isSubmitted = false,
  ): FormErrors {
    const formErrors: FormErrors = {};
    for (const field in validationMessages) {
      // clear previous error message (if any)
      formErrors[field] = '';
      const control = form.get(field);

      if (
        control &&
        control.errors &&
        ((!control.valid && control.touched && control.dirty && form.invalid) ||
          isSubmitted)
      ) {
        const messages = validationMessages[field];
        for (const key in control.errors) {
          formErrors[field] = messages[key];
          break;
        }
      }
    }
    return formErrors;
  }

  static tin(control: AbstractControl): ValidationResult | null {
    if (!control.value) {
      return null;
    }

    if (
      control.value.length > 2 &&
      parseInt(control.value.slice(0, 2)) > 0 &&
      parseInt(control.value.slice(0, 2)) < 38
    ) {
      return null;
    }
    return { state: true };
  }

  static minArrayLength(length: number) {
    return (control: AbstractControl): ValidationResult | null => {
      if (!control.value) {
        return null;
      }

      if (control.value.length >= length) {
        return null;
      }

      return { valid: false };
    };
  }

  static minObjectLength(length: number) {
    return (control: AbstractControl): ValidationResult | null => {
      if (!control.value) {
        return null;
      }

      if (Object.keys(control.value).length >= length) {
        return null;
      }

      return { valid: false };
    };
  }
}

export abstract class FormErrors {
  [key: string]: string;
}
export abstract class ValidationMessages {
  [key: string]: { [key: string]: string };
}

export interface ValidationResult {
  [key: string]: boolean;
}
